<script>
/**
 * Global mixin to provide helper utilities
 */
import { mapGetters } from 'vuex';

import { cancelAppointment } from '@/services/reschedule';

export default {
  data() {
    return {
      engineerIconWidth: this.$omwConfig.display.engineerIcon.width,
      engineerIconHeight: this.$omwConfig.display.engineerIcon.height,
      engineerIconAnchorX: this.$omwConfig.display.engineerIcon.anchorX,
      engineerIconAnchorY: this.$omwConfig.display.engineerIcon.anchorY,
      activityIconWidth: this.$omwConfig.display.activityIcon.width,
      activityIconHeight: this.$omwConfig.display.activityIcon.height,
      activityIconAnchorX: this.$omwConfig.display.activityIcon.anchorX,
      activityIconAnchorY: this.$omwConfig.display.activityIcon.anchorY,
      windowWidth: window.innerWidth,
      tooLateToRebookCancelStatuses: this.$omwConfig.reschedule.tooLateToRebookCancelStatuses || [
        'cancelled',
        'canceled',
        'completed',
        'enroute',
      ],
    };
  },
  computed: {
    ...mapGetters(['activityDetails', 'engineerDetails', 'apptInFuture', 'apptInPast']),
    allowUserRebook() {
      // We want to default to true if not specified
      const contactCentreRebook =
        typeof this.$omwConfig?.reschedule?.allowContactCentreRebook !== 'undefined'
          ? this.$omwConfig?.reschedule?.allowContactCentreRebook
          : true;
      if (contactCentreRebook) return true; // If contact centre user can rebook, this computed variable should always return true

      // Contact centre users aren't allowed to rebook so check if this is coming from a contact centre request
      return !this.contactCentre;
    },
    notesEnabled() {
      return this.$omwConfig?.notes?.enabled;
    },
    marketingEnabled() {
      return this.$omwConfig?.marketing?.enabled;
    },
    trustpilotEnabled() {
      return this.$omwConfig?.trustpilot?.enabled;
    },
    contactUsEnabled() {
      return !this.$omwConfig.display.disableContactUs;
    },
    calendarImportEnabled() {
      return this.$omwConfig?.calendarImport?.enabled;
    },
    additionalInfoEnabled() {
      return this.$omwConfig?.display?.additionalInfo?.enabled;
    },
    separatorEnabled() {
      return this.$omwConfig?.display.separatorEnabled;
    },
    feedbackEnabled() {
      return this.$omwConfig?.feedback?.enabled;
    },
    notificationEnabled() {
      return this.$omwConfig?.display?.notification?.show;
    },
    cancelEnabled() {
      return (
        this.$omwConfig?.reschedule?.cancelEnabled && // Reschedule option is turned on
        !this.tooLateToRebookCancelStatuses.includes(this.activityDetails.status) && // Activity is in a status that can be rescheduled
        this.activityDetails.rebookable && // Activity type is rebookable
        !this.apptInPast && // Activity is not in the past
        this.allowUserRebook && // User is allowed to rebook e.g. not a contact centre agent if that type is excluded
        this.inRebookableBucket && // Is in a bucket that has rebooking turned on
        !this.isPastCancelCutoffTime // If we're not allowed to rebook on the day, and appointment is today
      );
    },
    rescheduleEnabled() {
      return (
        this.$omwConfig?.reschedule?.rebookEnabled && // Reschedule option is turned on
        !this.tooLateToRebookCancelStatuses.includes(this.activityDetails.status) && // Activity is in a status that can be rescheduled
        this.activityDetails.rebookable && // Activity type is rebookable
        !this.apptInPast && // Activity is not in the past
        this.allowUserRebook && // User is allowed to rebook e.g. not a contact centre agent if that type is excluded
        this.inRebookableBucket && // Is in a bucket that has rebooking turned on
        !this.isPastRebookCutoffTime // If we're not allowed to rebook on the day, and appointment is today
      );
    },
    inRebookableBucket() {
      if (
        !this.$omwConfig?.reschedule?.rebookBuckets ||
        this.$omwConfig?.reschedule?.rebookBuckets.length === 0 // Rebooking allowed for all buckets if rebookBuckets in config is not specified or has no entries
      ) {
        return true;
      }
      return !!this.$omwConfig?.reschedule?.rebookBuckets.includes(this.engineerDetails.parentId);
    },
    apptToday() {
      return !this.apptInFuture && !this.apptInPast;
    },
    language() {
      return navigator.language;
    },
    engineerName() {
      if (this.apptInFuture) {
        return this.$t('future-engineer-name');
      }
      return this.engineerDetails.nameOverride || this.engineerDetails.name || this.$t('unknown-engineer-name');
    },
    isUnassigned() {
      return this.engineerName === this.$t('future-engineer-name');
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    showDisclaimer() {
      return (
        this.activityDetails && this.activityDetails.status !== 'completed' && this.activityDetails.status !== 'started'
      );
    },
    activityIcon() {
      return this.$omwConfig.display.activityIcon.svg;
    },
    engineerIcon() {
      return this.$omwConfig.display.engineerIcon.svg;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthListenerCallBack);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.widthListenerCallBack);
  },
  methods: {
    async submitCancel() {
      try {
        this.isLoading = true;
        await cancelAppointment(this.token);
        this.$buefy.toast.open(this.$t('rebook-appointment-cancelled'));
        await this.$router.push({
          name: 'Cancelled',
          query: {
            token: this.token,
          },
        });
      } catch (err) {
        this.cancellationError = this.$t('rebook-cancel-error', {
          contactNumber: this.$omwConfig.display.contactCentreNo,
        });
      } finally {
        this.isLoading = false;
      }
    },
    convertTrueFalseStringToBoolean(trueFalse) {
      if (trueFalse === undefined) return false;
      if (!trueFalse) {
        return false;
      }
      return trueFalse.toLowerCase() === 'true';
    },
    getOrientation(x) {
      if (x === undefined) {
        return 'desktop';
      } else {
        let y;
        if (x === 90 || x === -90) {
          y = 'landscape';
        } else {
          y = 'portrait';
        }
        return y;
      }
    },
    widthListenerCallBack() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
