<template>
  <div>
    <b-loading :active="isLoading" />
    <section class="has-text-centered">
      <div class="is-size-5">
        <p>{{ $t('rebook-currently-booked-for') }}</p>
        <p class="has-text-weight-bold">{{ currentBookedAppointmentDisplay }}</p>
      </div>
      <p class="mt-2">{{ daysRetrievedMsg }}</p>
      <div v-if="allowDatePicker" class="mt-2">
        <b-datepicker
          v-show="datepickerVisible"
          ref="datepicker"
          v-model="datepickerDateMediator"
          :events="eventsForDatePicker"
          inline
          :max-date="maxDate"
          :min-date="now"
          @input="handleDatePickerSelect"
        ></b-datepicker>
        <div v-show="!datepickerVisible" class="mb-0 pb-0">
          <b-button icon-left="calendar-day" type="is-datepicker-icon" @click="showDatePicker()" />
        </div>
      </div>
    </section>
    <section style="min-height: 50vh" class="mt-2">
      <!-- We set a key of activeDate to force tabs to re-render -->
      <b-tabs
        :key="activeDate"
        v-model="activeDate"
        position="is-centered"
        type="is-boxed"
        class="has-text-weight-semibold"
        style="margin-bottom: 0; padding-bottom: 0"
        @input="dateChanged"
      >
        <b-tab-item :label="dateForTab(-1)" :value="dateForTab(-1)" :disabled="shouldHideEarlierDate"></b-tab-item>
        <b-tab-item class="has-text-weight-semibold" :label="dateForTab(0)" :value="dateForTab(0)"></b-tab-item>
        <b-tab-item :label="dateForTab(1)" :value="dateForTab(1)" :disabled="shouldHideLaterDate"></b-tab-item>
      </b-tabs>
      <p class="has-text-centered mb-2">{{ $t('rebook-warning') }}</p>
      <p v-if="slotsForSelected && slotsForSelected.length > 0" class="has-text-centered mb-2">
        {{ $t('rebook-guidance') }}
      </p>
      <appointment-slot-holder
        :slots="slotsForSelected"
        :loading="isLoading"
        @accept="handleAccept"
      ></appointment-slot-holder>
      <p v-if="showTimezoneLabel && !isLoading" class="has-text-centered is-size-7 is-italic mt-1">
        {{ timezoneLabelText }}
      </p>
    </section>
    <section class="mt-2 has-text-centered">
      <div class="buttons is-centered">
        <appointment-home-button @home="goHome" />
      </div>
      <p v-if="retrievalError" class="mt-2 has-text-danger" v-html="retrievalError" />

      <appointment-error v-if="cancellationError" :error="cancellationError" />
      <appointment-remaining-time
        v-if="showExpiryTimer"
        :key="remainingTimeRerenderKey"
        class="mt-3"
        :timeout-length="$omwConfig.reschedule.expiryMins"
        @slots-expired="handleExpired"
      />
      <div v-else class="mt-2" style="height: 1.5em"></div>
      <appointment-legend />
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

import { getAppointmentData, acceptSlot } from '@/services/reschedule';
import AppointmentSlotHolder from './AppointmentSlotHolder.vue';
import AppointmentLegend from './AppointmentLegend.vue';
import AppointmentError from './AppointmentError.vue';
import AppointmentRemainingTime from './AppointmentRemainingTime.vue';
import AppointmentHomeButton from './AppointmentHomeButton.vue';

export default defineComponent({
  name: 'AppointmentRebookContainer',
  components: {
    AppointmentSlotHolder,
    AppointmentLegend,
    AppointmentError,
    AppointmentRemainingTime,
    AppointmentHomeButton,
  },
  data() {
    return {
      DateTime,
      calSelectedDate: DateTime.now(), // the selected DateTime
      appointmentsData: undefined, // the appointment slot data
      startOfPeriod: DateTime.now(), // the earliest date for retrieval of slots
      activeDate: undefined, // the selected date in string format
      cancellationError: undefined, // error from cancellation
      confirmationError: undefined, // error from confirmation
      retrievalError: undefined, // error from retrieval of slot data
      isLoading: false, // loading flag
      retrievedDates: [], // the dates that are currently retrieved so we know when to retrieve more when paging
      dateFormat: this.$omwConfig.reschedule.dateFormat,
      timeFormat: this.$omwConfig.reschedule.timeFormat,
      firstRetrieval: true, // we need to search forward for the 1st date with appts only on 1st retrieval
      remainingTimeRerenderKey: 0,
      datepickerVisible: false,
      datepickerDate: new Date(),
    };
  },
  computed: {
    ...mapGetters(['token', 'activityTimezone', 'apptWindowEnd']),
    now() {
      return new Date();
    },
    maxDate() {
      if (!this.apptWindowEnd)
        return DateTime.now()
          .plus({
            days: 180,
          })
          .toJSDate();
      return DateTime.fromISO(this.apptWindowEnd).toJSDate();
    },
    shouldHideEarlierDate() {
      const datetimeToCheck = this.calSelectedDate.minus({
        days: 1,
      });
      const now = DateTime.now();
      return datetimeToCheck.startOf('day') < now.startOf('day');
    },
    shouldHideLaterDate() {
      if (!this.apptWindowEnd) return false;
      const datetimeToCheck = this.calSelectedDate.plus({
        days: 1,
      });
      const maxDate = DateTime.fromISO(this.apptWindowEnd);
      return datetimeToCheck.startOf('day') >= maxDate.startOf('day');
    },
    allowDatePicker() {
      return this.$omwConfig?.reschedule?.enableDatepicker;
    },
    daysRetrievedMsg() {
      return this.$t('rebook-days-retrieved-msg', {
        daysRetrieved: this.$omwConfig.reschedule.daysToRetrieve,
      });
    },
    eventsForDatePicker() {
      return this.appointmentsData?.map((event) => {
        const date = DateTime.fromISO(event.startTime);
        return {
          date: date.toJSDate(),
          type: 'is-info',
        };
      });
    },
    datepickerDateMediator: {
      get() {
        if (!this.activeDate) return new Date();
        return DateTime.fromFormat(this.activeDate, this.dateFormat).toJSDate();
      },
      set(newVal) {
        this.datepickerDate = DateTime.fromJSDate(newVal).toJSDate();
      },
    },
    showExpiryTimer() {
      return (
        this.$omwConfig?.reschedule?.expiryMins > 0 &&
        this.appointmentsData &&
        this.appointmentsData.length > 0 &&
        !this.retrievalError
      );
    },
    showTimezoneLabel() {
      return (this.$omwConfig.reschedule.showTimezoneLabel && this.appointmentsData?.length) || false;
    },
    timezoneLabelText() {
      return this.$t('rebook-timezone-label');
    },
    currentBookedAppointmentDisplay() {
      const apptDate = DateTime.fromISO(this.activityDetails.date, {
        zone: this.activityTimezone,
      });
      const timeFrom = DateTime.fromFormat(this.activityDetails.trimServiceWindowStart, 'hh:mm', {
        zone: this.activityTimezone,
      });
      const timeTo = DateTime.fromFormat(this.activityDetails.trimServiceWindowEnd, 'hh:mm', {
        zone: this.activityTimezone,
      });

      return `${apptDate.toFormat(this.dateFormat)} ${timeFrom.toFormat(this.timeFormat).toLowerCase()} - ${timeTo
        .toFormat(this.timeFormat)
        .toLowerCase()}`;
    },
    slotsForSelected() {
      return this?.appointmentsData?.filter((slot) => {
        const startDateTime = DateTime.fromISO(slot.startTime);
        return startDateTime.startOf('day').equals(this.calSelectedDate.startOf('day'));
      });
    },
  },
  watch: {
    datepickerDate: {
      handler(newVal) {
        this.activeDate = DateTime.fromJSDate(newVal).toFormat(this.dateFormat);
      },
    },
    activeDate: {
      async handler(newVal, oldVal) {
        if (!newVal) return;
        if (newVal === oldVal) return;
        const selectedDatetime = DateTime.fromFormat(newVal, this.dateFormat);
        const now = DateTime.now();
        if (selectedDatetime.startOf('day') < now.startOf('day')) return;
        if (!this.retrievedDates.includes(newVal)) {
          await this.retrieveSlots();
        }
      },
    },
    confirmationError: {
      handler(message) {
        if (message) {
          this.$buefy.dialog.alert({
            title: 'Error',
            message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            ariaRole: 'alertdialog',
            ariaModal: true,
            onConfirm: this.retrieveSlots,
          });
        }
      },
    },
  },
  async mounted() {
    this.resetSlotData();
  },
  methods: {
    ...mapActions(['setRescheduleDetails', 'setOmwData']),
    showDatePicker() {
      this.datepickerVisible = true;
      this.$refs.datepicker.toggle();
    },
    handleDatePickerSelect(evt) {
      this.datepickerVisible = false;
      const date = new Date(evt);
      const formattedDate = DateTime.fromJSDate(date).toFormat(this.dateFormat);
      this.dateChanged(formattedDate);
    },
    async goHome() {
      try {
        this.isLoading = true;
        const passcode = sessionStorage.getItem('passcode');
        await this.setOmwData(passcode);
      } finally {
        this.isLoading = false;
        this.$router.push({
          name: 'Home',
          query: {
            token: this.token,
          },
        });
      }
    },
    handleExpired() {
      this.$buefy.dialog.confirm({
        title: this.$t('rebook-expired-title'),
        message: this.$t('rebook-expired-message'),
        type: 'is-info',
        hasIcon: true,
        icon: 'exclamation-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
        cancelText: this.$t('rebook-expire-cancel'),
        confirmText: this.$t('rebook-expire-new-slots'),
        onConfirm: this.retrieveSlots,
        onCancel: this.handleAbort,
      });
    },
    handleAbort() {
      this.appointmentsData = [];
      this.$router.replace({
        name: 'Home',
        query: {
          token: this.token,
        },
      });
    },
    buildDateRange() {
      const dates = [];
      let latestDate;
      for (let i = 0; i < this.$omwConfig.reschedule.daysToRetrieve; i++) {
        const date = this.startOfPeriod.plus({
          days: i,
        });
        const formattedDate = date.toFormat(this.dateFormat);
        latestDate = date;
        dates.push(formattedDate);
      }
      // Set the start of the retrieval period to be the day after the last retrieved date
      this.startOfPeriod = latestDate.plus({
        days: 1,
      });
      this.retrievedDates.push(...dates);
      if (!this.$omwConfig.reschedule.allowStartSameDay) {
        // Add today so it's not retrieved
        const nowString = DateTime.now().toFormat(this.dateFormat);
        this.retrievedDates.push(nowString);
      }
    },
    resetSlotData() {
      this.appointmentsData = [];
      this.retrievedDates = [];
      if (!this.$omwConfig.reschedule.allowEarlierThanOriginal) {
        this.startOfPeriod = DateTime.fromISO(this.activityDetails.date);
      } else {
        this.startOfPeriod = DateTime.now();
      }
      if (!this.$omwConfig.reschedule.allowStartSameDay || !this.$omwConfig.reschedule.allowEarlierThanOriginal) {
        this.startOfPeriod = this.startOfPeriod.plus({
          days: 1,
        });
      }
      this.activeDate = this.dateForTab(0);
    },
    filterAndSortSlots(slots) {
      return slots
        .filter((slot) => {
          if (this.$omwConfig.reschedule.hideRagRed) {
            if (slot.ragValue === 'RED') return false;
          }
          return true;
        })
        .sort((a, b) => {
          const dateA = DateTime.fromISO(a.startTime);
          const dateB = DateTime.fromISO(b.startTime);
          if (dateA.startOf('day') > dateB.startOf('day')) return 1;
          if (dateA.startOf('day') < dateB.startOf('day')) return -1;
          return 0;
        });
    },
    async retrieveSlots() {
      if (this.confirmationError) {
        this.resetSlotData();
      }
      try {
        this.isLoading = true;
        this.retrievalError = undefined;
        this.confirmationError = undefined;

        const slotData = await getAppointmentData(
          this.token,
          this.startOfPeriod.toISODate(),
          this.$omwConfig.reschedule.daysToRetrieve,
          this.activityDetails.timezone,
        );
        const filteredSlotData = this.filterAndSortSlots(slotData);

        // Add the newly retrieved data to the already retrieved data
        filteredSlotData.forEach((slot) => {
          const slotAlreadyInList = this.appointmentsData.some((originalSlot) => {
            return originalSlot.apptRequestId === slot.apptRequestId && originalSlot.slotId === slot.slotId;
          });
          if (!slotAlreadyInList) {
            this.appointmentsData.push(slot);
          }
        });
        if (this.appointmentsData.length === 0) {
          this.retrievalError = this.$t('rebook-retrieval-none-found', {
            contactNumber: this.$omwConfig.display.contactCentreNo,
          });
          this.buildDateRange();
          return;
        }
        if (this.firstRetrieval) {
          this.calSelectedDate = DateTime.fromISO(this.appointmentsData[0].startTime);
        }
        this.activeDate = this.dateForTab(0);
        this.buildDateRange();
        this.firstRetrieval = false;
      } catch (err) {
        this.retrievalError = this.$t('rebook-retrieval-error', {
          contactNumber: this.$omwConfig.display.contactCentreNo,
        });
      } finally {
        this.isLoading = false;
        this.remainingTimeRerenderKey = this.remainingTimeRerenderKey + 1;
      }
    },
    dateForTab(offset) {
      return this.calSelectedDate
        .plus({
          days: offset,
        })
        .toFormat(this.dateFormat);
    },
    dateChanged(evt) {
      if (!evt) return;
      this.calSelectedDate = DateTime.fromFormat(evt, this.dateFormat);
      this.datepickerDateMediator = DateTime.fromFormat(evt, this.dateFormat).toJSDate();
    },
    async handleAccept(evt) {
      try {
        this.isLoading = true;
        const acceptPayload = {
          token: this.token,
          slotId: evt.slotId,
          startTime: evt.startTime,
          endTime: evt.endTime,
          apptRequestId: evt.apptRequestId,
        };
        this.confirmationError = undefined;
        const result = await acceptSlot(acceptPayload);
        if (result) {
          this.setRescheduleDetails(acceptPayload);
          this.$router.push({
            name: 'Confirmed',
            query: {
              token: this.token,
            },
          });
        } else {
          this.confirmationError = this.$t('rebook-no-longer-available');
        }
      } catch (error) {
        this.confirmationError = this.$t('rebook-confirm-appointment-error', {
          contactNumber: this.$omwConfig.display.contactCentreNo,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.is-active {
  font-weight: bold !important;
}

.custom-datepicker {
  color: red;
  background-color: red;
}
</style>

.is-active { font-weight: bold !important; } .custom-datepicker { color: red; background-color: red; }
