<template>
  <span>
    <b-loading :active.sync="isLoading" is-full-page />
    <b-button class="is-home-button has-text-weight-semibold" @click="goHome()">{{
      $t('rebook-home-button-label')
    }}</b-button>
  </span>
</template>

<script>
import { mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'PostProcessReturnButton',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['setRescheduleDetails', 'setOmwData']),
    async goHome() {
      try {
        this.isLoading = true;
        const passcode = sessionStorage.getItem('passcode');
        await this.setOmwData(passcode);
      } finally {
        this.isLoading = false;
        this.$router.replace({
          name: 'Home',
        });
        this.setRescheduleDetails(null);
      }
    },
  },
});
</script>
