import * as FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import i18n from '@/lang/i18n';
import configData from '@/config/config';

const ICAL_DATE_FORMAT = 'yyyyLLdd';
const ICAL_TIME_FORMAT = 'HHmmss';

const GOOGLE_CALENDAR_DATE_FORMAT = 'yyyyLLdd';
const GOOGLE_CALENDAR_TIME_FORMAT = 'HHmmss';

const DATE_BUILD_FORMAT = 'yyyy-LL-dd HH:mm';
const TIME_FORMAT = 'hh:mm';
const PRODID = 'Leadent Digital - On My Way';
const MAX_LINE_SIZE = 75;

/**
 * @param {boolean} isGoogle True if this results in a Google calendar link or false for an .ics file
 * @param {Object} activityDetails The full OMW response object that will be used for substitutions in the template
 * @param {string} descriptionTemplate A string with replacement tokens corresponding to the fields in the activityDetails object e.g. ${date}, ${trimServiceWindowStart}
 */
export function buildDescriptionText(
  isGoogle,
  activityDetails,
  configDateFormat = 'MMM dd, yyyy',
  configTimeFormat = 'h:mma',
) {
  const activity = activityDetails;

  return i18n.t('calendar-entry-description', {
    trimServiceWindowStart: DateTime.fromFormat(activity.trimServiceWindowStart, TIME_FORMAT).toFormat(
      configTimeFormat,
    ),
    trimServiceWindowEnd: DateTime.fromFormat(activity.trimServiceWindowEnd, TIME_FORMAT).toFormat(configTimeFormat),
    date: DateTime.fromISO(activity.date).toFormat(configDateFormat),
    apptNumber: activity.apptNumber,
    streetAddress: activity.streetAddress,
    city: activity.city,
    postalCode: activity.postalCode,
    url: buildUrl(isGoogle),
  });
}

/**
 *
 * @param {boolean} isGoogle
 * @return {string}
 */
function buildUrl(isGoogle) {
  if (isGoogle) return `<a href="${window.location.href}&clickOrigin=calendar">this link</a>`;
  else return `${window.location.href}&clickOrigin=calendar`;
}

/**
 * @param {Object} calendarDetails
 * @param {string} calendarDetails.address
 * @param {string} calendarDetails.description
 * @param {string} calendarDetails.summary
 * @param {string} calendarDetails.timezone
 * @param {string} calendarDetails.start
 * @param {string} calendarDetails.apptStartTime
 * @param {string} calendarDetails.apptEndTime
 * @param {string} calendarDetails.token Used to uniquely identify the appointment so updates work
 * @param {string} calendarDetails.organizer
 * @returns
 */
export async function createIcsFile(calendarDetails) {
  const ical = generateiCal(calendarDetails);
  const filePrefix = configData?.calendarImport?.filePrefix || 'omw';
  download(`${filePrefix}-calendar.ics`, ical);
}

/**
 * .ics files require that lines are not longer than 75 characters long, and that
 * each folded line must have a carriage return followed by a single character of whitespace
 * See: https://icalendar.org/iCalendar-RFC-5545/3-1-content-lines.html
 * @param {string} input
 * @returns
 */
function foldDescriptionLine(input) {
  const tag = 'DESCRIPTION:';
  if (!input) return '';
  let output = '';
  const totalLength = input.length;
  if (input.length <= MAX_LINE_SIZE) return input;

  let index = 0;
  let firstLine = true;
  while (index < totalLength) {
    const charsToShift = MAX_LINE_SIZE - tag.length;
    if (firstLine) {
      // the first line length needs to take into account DESCRIPTION:
      output += `${input.substring(index, index + charsToShift)}\n `;
      firstLine = false;
      index += charsToShift;
    } else {
      output += `${input.substring(index, index + MAX_LINE_SIZE)}\n `;
      index += MAX_LINE_SIZE;
    }
  }
  // remove last space
  output = output.substring(0, output.length - 1);
  return output;
}

/**
 * @param {Object} calendarDetails
 * @param {string} calendarDetails.address
 * @param {string} calendarDetails.description
 * @param {string} calendarDetails.summary
 * @param {string} calendarDetails.start
 * @param {string} calendarDetails.apptStartTime
 * @param {string} calendarDetails.apptEndTime
 * @param {string} calendarDetails.token Used to uniquely identify the appointment so updates work
 * @param {string[]} calendarDetails.alarms
 * @returns
 */
function generateiCal(calendarDetails) {
  const { token, address, summary } = calendarDetails;
  const description = foldDescriptionLine(calendarDetails.description);
  const alarms = createAlarms(calendarDetails.alarms, calendarDetails.token);
  const start = DateTime.fromFormat(`${calendarDetails.date} ${calendarDetails.apptStartTime}`, DATE_BUILD_FORMAT);
  const end = DateTime.fromFormat(`${calendarDetails.date} ${calendarDetails.apptEndTime}`, DATE_BUILD_FORMAT);
  const url = `${window.location.href}&clickOrigin=calendar`;
  const startDateTime = `${start.toFormat(ICAL_DATE_FORMAT)}T${start.toFormat(ICAL_TIME_FORMAT)}`;
  const endDateTime = `${end.toFormat(ICAL_DATE_FORMAT)}T${end.toFormat(ICAL_TIME_FORMAT)}`;
  const now = DateTime.utc();
  const dtstamp = `${now.toFormat(ICAL_DATE_FORMAT)}T${now.toFormat(ICAL_TIME_FORMAT)}`;
  const organizer = calendarDetails.organizer;

  return `BEGIN:VCALENDAR\nMETHOD:PUBLISH\nVERSION:2.0\nPRODID:${PRODID}\nCALSCALE:GREGORIAN\nBEGIN:VEVENT\nURL;VALUE=URI:${url}\nORGANIZER;${organizer}\nDTSTAMP:${dtstamp}\nSUMMARY:${summary}\nUID:${token}\nDTSTART:${startDateTime}\nDTEND:${endDateTime}\nLOCATION=${address}\nSTATUS:CONFIRMED\nDESCRIPTION:${description}${alarms}\n${alarms}END:VEVENT\nEND:VCALENDAR`;
}

/**
 *
 * @param {string[]} alarms An array of timespans representing the alarms to create
 * @param {string} token The unique token for this appointment
 */
function createAlarms(alarms, token) {
  let output = '';
  alarms.forEach((alarm, idx) => {
    output += `BEGIN:VALARM\nACTION:DISPLAY\nDESCRIPTION:REMINDER\nTRIGGER:${alarm}\nUID:${token}-${idx}\nEND:VALARM\n`;
  });
  return output;
}

/**
 * Downloads the given ics when Safari is determined as the browser
 *
 * @param {string} data - ics data
 * @param {string} fileName - file name to save, ending in .ics
 */
const safariFileSave = (data, fileName) => {
  const anchor = document.createElement('a');
  const encodedData = encodeURIComponent(data);

  anchor.setAttribute('href', `data:text/calendar;charset=utf-8,${encodedData}`);
  anchor.setAttribute('download', fileName);

  if (document.createEvent) {
    const event = new Event('click', { bubbles: true, cancelable: true });
    anchor.dispatchEvent(event);
  } else {
    anchor.click();
  }
};

/**
 * Downloads the given ics as an iCalendar file.
 *
 * @param {string} fileName - filename of the event file
 * @param {string} data - ics data
 */
const download = (fileName, data) => {
  if (isSafari()) {
    safariFileSave(data, fileName);
  } else {
    const blob = getBlob(data);

    FileSaver.saveAs(blob, fileName);
  }
};

/**
 * Returns true if the current browser is Safari.
 *
 * @returns {boolean}
 */
const isSafari = () => {
  return (
    window.hasOwnProperty('safari') ||
    // check to ensure navigator is not Chrome (which includes Safari in the user agent)
    (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      // browsers on iOS are wrappers around Safari, but include CriOS (Chrome), FxiOS (Firefox), etc.
      !/(cr|fx)ios[^a-z]/i.test(navigator.userAgent))
  );
};

/**
 * The name of the file will be the event title with alphanumeric chars with the extension `.ics`.
 *
 * @param {string} icsData
 * @returns {Blob}
 */
const getBlob = (icsData) => {
  return new Blob([icsData], {
    type: 'application/octet-stream', // TODO: change to text/calendar?
  });
};

// const formatDateTimeAsGoogleCalendarDateTime(date)

/**
 * @param {Object} calendarDetails
 * @param {string} calendarDetails.address
 * @param {string} calendarDetails.description
 * @param {string} calendarDetails.summary
 * @param {string} calendarDetails.timezone
 * @param {string} calendarDetails.start
 * @param {string} calendarDetails.apptStartTime
 * @param {string} calendarDetails.apptEndTime
 * @param {string} calendarDetails.token Used to uniquely identify the appointment so updates work
 * @param {string} calendarDetails.organizer
 * @returns {string}
 */
export function buildGoogleCalendarLink(calendarDetails) {
  const start = DateTime.fromFormat(`${calendarDetails.date} ${calendarDetails.apptStartTime}`, DATE_BUILD_FORMAT);
  const end = DateTime.fromFormat(`${calendarDetails.date} ${calendarDetails.apptEndTime}`, DATE_BUILD_FORMAT);
  const dates = `${start.toFormat(GOOGLE_CALENDAR_DATE_FORMAT)}T${start.toFormat(
    GOOGLE_CALENDAR_TIME_FORMAT,
  )}/${end.toFormat(GOOGLE_CALENDAR_DATE_FORMAT)}T${end.toFormat(GOOGLE_CALENDAR_TIME_FORMAT)}`;

  const baseUrl = `https://www.google.com/calendar/render`;
  const params = {
    action: 'TEMPLATE',
    location: calendarDetails.address,
    details: calendarDetails.description,
    dates,
    text: calendarDetails.summary,
  };
  const queryParams = new URLSearchParams(params);
  return `${baseUrl}?${queryParams.toString()}`;
}
