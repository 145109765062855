import Vue from 'vue';
import VueRouter from 'vue-router';
import OnMyWay from '@/views/Home.vue';
import CustomLoading from '@/components/CustomLoading.vue';
import AppointmentRebook from '@/components/appointmentBooking/AppointmentRebookContainer.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: OnMyWay,
  },
  {
    path: '/',
    name: 'Loading',
    component: CustomLoading,
  },
  {
    path: '/cancelled',
    name: 'Cancelled',
    component: () =>
      import(/* webpackChunkName: "Cancelled" */ '@/components/appointmentBooking/AppointmentCancelConfirmation.vue'),
  },
  {
    path: '/rebook',
    name: 'Rebook',
    component: AppointmentRebook,
  },
  {
    path: '/confirmed',
    name: 'Confirmed',
    component: () =>
      import(/* webpackChunkName: "Confirmed" */ '@/components/appointmentBooking/AppointmentRebookConfirmation.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

export default router;
