createIcsFile
<template>
  <div class="content">
    <div class="columns">
      <div class="column has-text-left">
        <p
          class="mx-2 my-0 py-0"
          v-html="
            $t('app-panel-appointment-number', {
              appointmentNumber: activityDetails.apptNumber,
              boldStart,
              boldEnd,
            })
          "
        ></p>
        <p class="mx-2 my-0 py-0" v-html="line1"></p>
        <p v-if="address != null" class="mx-2 my-0 py-0" v-html="address" />
        <p v-if="showDisclaimer" class="mx-2 my-0 py-0 is-size-6 is-italic" style="padding-top: 0.5em">
          {{ $t('app-disclaimer-text') }}
        </p>

        <div class="columns is-mobile">
          <div class="column mt-2">
            <div class="is-flex is-flex-direction-row is-justify-content-center is-flex-wrap-wrap" style="gap: 4px">
              <b-button
                v-if="rescheduleEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                type="is-rebook-button"
                @click="showReschedule"
                >{{ $t('rebook-rebook-button-label') }}</b-button
              >
              <AppointmentCancellationButton v-if="cancelEnabled" @cancel="handleCancel">
              </AppointmentCancellationButton>

              <b-button
                v-if="calendarImportEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                :loading="isLoading"
                type="is-calendar-button"
                @click="createCalendarFile"
                >{{ $t('calendar-import-button-label') }}</b-button
              >
              <b-button
                v-if="calendarImportEnabled"
                class="has-text-weight-semibold"
                style="width: 10rem"
                size="is-small"
                :loading="isLoading"
                type="is-calendar-button"
                @click="createGoogleCalendarLink"
                >{{ $t('calendar-google-button-label') }}</b-button
              >
              <b-button
                v-if="notesEnabled"
                size="is-small"
                style="width: 10rem"
                class="has-text-weight-semibold"
                :loading="isLoading"
                type="is-dark"
                @click="showNotes"
                >{{ $t('notes-main-button-label') }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import styles from '@/sass/variables.scss';
import { createIcsFile, buildDescriptionText, buildGoogleCalendarLink } from '@/services/calendar-creator';

import AppointmentCancellationButton from '@/components/appointmentBooking/AppointmentCancellationButton.vue';
import NotesModal from '@/components/NotesModal.vue';

const boldStart = `<span style="font-weight: bolder" class="has-text-appointment-details-panel-text">`;
const boldEnd = `</span>`;

export default defineComponent({
  components: {
    AppointmentCancellationButton,
  },
  props: {
    width: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      boldStart,
      boldEnd,
      styles,
      photoSource: undefined,
      line1: undefined,
      address: undefined,
      isLoading: false,
      ical: undefined,
      googleCalendarLink: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'formattedDate',
      'apptInFuture',
      'apptInPast',
      'calculatedEta',
      'formattedServiceWindowStart',
      'formattedServiceWindowEnd',
      'formattedDeliveryWindowStart',
      'formattedDeliveryWindowEnd',
      'token',
    ]),
    startedStatusText() {
      return this.$t('app-panel-started', {
        name: this.apptPanelEngineerName,
        boldStart,
        boldEnd,
      });
    },
    completeStatusText() {
      return this.$t('app-panel-completed', {
        name: this.apptPanelEngineerName,
        boldStart,
        boldEnd,
      });
    },
    cancelledStatusText() {
      return this.$t('app-panel-cancelled', {
        contactCentreNumber: this.$omwConfig.display.contactCentreNo,
      });
    },
    abortedStatusText() {
      return this.$t('app-panel-aborted', {
        contactCentreNumber: this.$omwConfig.display.contactCentreNo,
      });
    },
    notDoneStatusText() {
      return this.$t('app-panel-notdone', {
        contactCentreNumber: this.$omwConfig.display.contactCentreNo,
      });
    },
    suspendedStatusText() {
      return this.$t('app-panel-suspended');
    },
    addressArrived() {
      if (this.activityDetails) {
        return this.$t('app-panel-street-address', {
          street: this.activityDetails.streetAddress,
          city: this.activityDetails.city,
          postcode: this.activityDetails.postalCode,
        });
      }
      return '';
    },
    timeslot() {
      if (this.activityDetails) {
        return this.$t('app-panel-today-pending-timeslot', {
          start: this.formattedServiceWindowStart,
          end: this.formattedServiceWindowEnd,
          boldStart,
          boldEnd,
        });
      }
      return '';
    },
    builtAddress() {
      if (this.engineerDetails && this.activityDetails) {
        const substitutions = {
          name: this.apptPanelEngineerName,
          street: this.activityDetails.streetAddress,
          city: this.activityDetails.city,
          postcode: this.activityDetails.postalCode,
          boldStart,
          boldEnd,
        };
        if (this.apptInPast) {
          return this.$t('app-panel-street-address', substitutions);
        }
        if (!this.apptInFuture) {
          return this.$t('app-panel-street-address-present-prefix', substitutions);
        } else {
          return this.$t('app-panel-street-address-future-prefix', substitutions);
        }
      }
      return '';
    },
    pendingStatusText() {
      // Check to see if this activity is in the future
      if (this.apptInFuture) {
        return this.pendingFutureText;
      }
      if (this.apptInPast) {
        return '';
      } else {
        return this.pendingTodayText;
      }
    },
    pendingFutureText() {
      let start;
      let end;
      if (this.$omwConfig.display.showDeliveryForFuture) {
        // Check if have a delivery window, if not, use service window
        start = this.formattedDeliveryWindowStart
          ? this.formattedDeliveryWindowStart
          : this.formattedServiceWindowStart;
        end = this.formattedDeliveryWindowEnd ? this.formattedDeliveryWindowEnd : this.formattedServiceWindowEnd;
      } else {
        start = this.formattedServiceWindowStart;
        end = this.formattedServiceWindowEnd;
      }

      return this.$t('app-panel-future-pending', {
        start,
        end,
        date: this.formattedDate,
        boldStart,
        boldEnd,
      });
    },
    pendingTodayText() {
      let arrivalTimeEnd, arrivalTimeStart;
      // If this job is not the next in the engineer's route, or we don't have ETA calculation turned on, use the provided value
      if (this.relativePositionInRoute !== 0 || !this.$omwConfig?.display?.eta?.useCalculatedEta) {
        arrivalTimeStart = this.formattedDeliveryWindowStart || this.formattedServiceWindowStart;
        arrivalTimeEnd = this.formattedDeliveryWindowEnd || this.formattedServiceWindowEnd;
      } else {
        // If this job is the next in the engineer's route, use an ETA calculated from the map provider
        arrivalTimeStart = this?.calculatedEta?.bufferedStartDate || this.formattedDeliveryWindowStart; // fall back to provided value if necessary
        arrivalTimeEnd = this?.calculatedEta?.bufferedEndDate || this.formattedDeliveryWindowEnd; // fall back to provided value if necessary
      }
      const substitutions = {
        name: this.apptPanelEngineerName,
        start: arrivalTimeStart,
        end: arrivalTimeEnd,
        boldStart,
        boldEnd,
      };
      // Not in a route yet so don't show route position
      if (this.relativePositionInRoute < 0) {
        return this.$t('app-panel-suspended', substitutions);
      }
      // One stop out
      if (this.relativePositionInRoute === 1) {
        return this.$t('app-panel-today-pending-one-stop-before', substitutions);
      }
      // Next stop
      if (this.relativePositionInRoute === 0) {
        return this.$t('app-panel-today-pending-next-stop', substitutions);
      }
      // More than 1 away
      return this.$t('app-panel-today-pending-multiple-stops', {
        ...substitutions,
        stops: this.relativePositionInRoute,
      });
    },
    enrouteStatusText() {
      let start;
      let end;
      if (this.$omwConfig?.display?.eta?.useCalculatedEta) {
        start = this?.calculatedEta?.bufferedStartDate;
        end = this?.calculatedEta?.bufferedEndDate;
      } else {
        start = this.formattedDeliveryWindowStart; // fall back to provided value if necessary
        end = this.formattedDeliveryWindowEnd; // fall back to provided value if necessary
      }

      return this.$t('app-panel-today-pending-next-stop', {
        name: this.apptPanelEngineerName,
        start,
        end,
        boldStart,
        boldEnd,
      });
    },

    relativePositionInRoute() {
      return this?.activityDetails?.positionInRoute - 1;
    },
    horizontalRuleStyle() {
      return {
        backgroundColor: styles.appointmentDetailsPanel,
        padding: 0,
        margin: 0,
        border: 0,
        borderTop: '1px dotted rgba(207, 212, 214) !important',
      };
    },
    appointmentDetailsStyle() {
      return {
        color: 'white',
      };
    },
    apptPanelEngineerName() {
      if (this.apptInFuture) {
        return this.$t('future-engineer-name');
      }
      return (
        this.engineerDetails.nameOverride ||
        this.engineerDetails.name ||
        this.$t('appointment-panel-default-engineer-name')
      );
    },
  },
  watch: {
    activityDetails: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.activityDetails || !this.activityDetails.status) return;
        const status = this.activityDetails.status.toLowerCase();

        switch (status) {
          case 'enroute': {
            this.line1 = this.enrouteStatusText;
            this.address = this.builtAddress;
            break;
          }
          case 'pending': {
            this.line1 = this.pendingStatusText;
            this.address = this.builtAddress;
            break;
          }
          case 'started': {
            this.line1 = this.startedStatusText;
            break;
          }
          case 'completed': {
            this.line1 = this.completeStatusText;
            this.address = undefined;
            break;
          }
          case 'cancelled': {
            this.line1 = this.cancelledStatusText;
            this.address = undefined;
            break;
          }
          case 'not done':
          case 'canceled':
          case 'notdone': {
            this.line1 = this.notDoneStatusText;
            this.address = undefined;
            break;
          }
          case 'aborted': {
            this.line1 = this.abortedStatusText;
            this.address = undefined;
            break;
          }
          case 'suspended': {
            this.line1 = this.suspendedStatusText;
            break;
          }
        }
        this.$forceUpdate();
      },
    },
  },
  methods: {
    showNotes() {
      this.$buefy.modal.open({
        parent: this,
        component: NotesModal,
        hasModalCard: true,
        props: {
          token: this.token,
        },
      });
    },
    handleCancel() {
      this.$buefy.dialog.confirm({
        title: this.$t('rebook-cancel-title'),
        message: this.$t('rebook-cancel-message'),
        confirmText: this.$t('rebook-cancel-confirm'),
        cancelText: this.$t('rebook-return-label'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.submitCancel,
      });
    },
    showReschedule() {
      this.$router.push({
        name: 'Rebook',
        query: {
          token: this.token,
        },
      });
    },
    createCalendarFile() {
      this.isLoading = true;
      try {
        const description = buildDescriptionText(false, this.activityDetails);
        this.ical = createIcsFile({
          address: `${this.activityDetails.streetAddress}, ${this.activityDetails.city}, ${this.activityDetails.postalCode}`,
          description,
          summary: this.$t('calendar-entry-title', {
            apptNumber: this.activityDetails.apptNumber,
          }),
          date: this.activityDetails.date,
          apptStartTime: this.activityDetails.trimServiceWindowStart,
          apptEndTime: this.activityDetails.trimServiceWindowEnd,
          token: this.token,
          alarms: this.$omwConfig?.calendarImport?.alarms || [],
          organizer: this.$omwConfig.calendarImport.organizer,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    createGoogleCalendarLink() {
      this.isLoading = true;
      try {
        const description = buildDescriptionText(true, this.activityDetails);
        this.googleCalendarLink = buildGoogleCalendarLink({
          address: `${this.activityDetails.streetAddress}, ${this.activityDetails.city}, ${this.activityDetails.postalCode}`,
          description,
          summary: this.$t('calendar-entry-title', {
            apptNumber: this.activityDetails.apptNumber,
          }),
          date: this.activityDetails.date,
          apptStartTime: this.activityDetails.trimServiceWindowStart,
          apptEndTime: this.activityDetails.trimServiceWindowEnd,
          token: this.token,
          alarms: this.$omwConfig?.calendarImport?.alarms || [],
          organizer: this.$omwConfig.calendarImport.organizer,
        });
        window.open(this.googleCalendarLink, '_blank');
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.content {
  @if $appointment-details-panel {
    background-color: $appointment-details-panel;
  } @else {
    background-color: $engineer-panel;
  }
  @if $appointment-details-panel-text {
    color: $appointment-details-panel-text;
  } @else {
    color: $engineer-panel-text;
  }
  border-radius: 4px;
}
</style>
