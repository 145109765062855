<template>
  <div class="modal-card" style="width: auto">
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('notes-modal-title') }}</p>
    </header>
    <section class="modal-card-body">
      <div v-for="note in previousNotes" :key="note.date">
        <b-message :closable="false" size="is-small" style="margin-bottom: 0.4em">
          <span class="has-text-weight-semibold">{{ convertTimestamp(note.timestamp) }}</span
          >: {{ note.text }}
        </b-message>
      </div>
      <b-field v-if="!maxNotesReached">
        <b-input
          v-model="newNoteText"
          :maxlength="maxNoteLength"
          type="textarea"
          :placeholder="$t('notes-input-placeholder')"
        ></b-input>
      </b-field>
      <p v-else class="pt-2 has-text-weight-semibold has-text-centered">
        {{ $t('notes-max-reached-label') }}
      </p>
      <p class="is-size-7 has-text-centered">
        {{ $t('notes-disclaimer-label') }}
      </p>
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t('notes-close-button-label') }}</b-button>
      <b-button type="is-success" :disabled="isNoteTextEmpty" @click="addNote">{{
        $t('notes-add-button-label')
      }}</b-button>
    </footer>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { getNotes, postNote } from '@/services/notes';

export default {
  name: 'NotesModal',
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      previousNotes: [],
      newNoteText: undefined,
      maxNotesReached: false,
      maxNoteLength: this.$omwConfig?.notes?.maxNoteLength || 300,
    };
  },
  computed: {
    isNoteTextEmpty() {
      return !this.newNoteText || this.newNoteText?.length === 0;
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.handleNotesResponse(await getNotes(this.token));
    } catch (err) {
      this.$buefy.toast.open({
        message: this.$t('notes-fetch-failure-message'),
        type: 'is-danger',
      });
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    convertTimestamp(timestamp) {
      return DateTime.fromISO(timestamp).toFormat(this.$omwConfig?.notes?.dateTimeFormat);
    },
    async addNote() {
      try {
        this.isLoading = true;
        this.handleNotesResponse(await postNote(this.token, { text: this.newNoteText }));
        this.newNoteText = undefined;
        this.$buefy.toast.open({
          message: this.$t('notes-add-success-message'),
          type: 'is-success',
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: this.$t('notes-add-failure-message'),
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleNotesResponse(notesResponse) {
      this.previousNotes = notesResponse.notes;
      this.maxNotesReached = notesResponse.maxAllowed && notesResponse.notes.length >= notesResponse.maxAllowed;
    },
  },
};
</script>
