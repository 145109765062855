<template>
  <div v-if="publishedCampaign.url" class="has-text-centered" @click="recordClick">
    <a :href="publishedCampaign.clickUrl" target="_new"
      ><img :src="publishedCampaign.url" style="border-radius: 5px"
    /></a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { submitClick, getCampaign } from '@/services/marketing';

export default defineComponent({
  data() {
    return {
      publishedCampaign: {},
    };
  },
  computed: {
    ...mapGetters(['activityDetails', 'datasetId']),
  },
  async created() {
    try {
      this.publishedCampaign = await getCampaign();
      if (this.publishedCampaign) {
        this.$emit('campaign-loaded', true);
      }
    } catch (err) {
      this.$emit('campaign-failed', true);
    }
  },
  methods: {
    async recordClick() {
      try {
        await submitClick(this.activityDetails, this.engineerName, this.publishedCampaign, this.datasetId);
      } catch (err) {
        console.error(err);
      }
    },
  },
});
</script>
