<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />

    <div v-if="passcodeEnabled && !previouslyLoggedIn()" class="centered">
      <form @submit.prevent="submitPasscode" @keyup.enter="submitPasscode()">
        <div class="columns">
          <div class="column">
            <b-field :label="$t('passcode-input-label')" style="padding-top: 1em; text-align: center">
              <b-input
                v-model="passcode"
                style="width: 250px"
                :placeholder="$t('passcode-input-placeholder')"
                type="password"
                password-reveal
                @keyup.native.enter="submitPasscode"
              />

              <b-button class="button is-primary" :disabled="!passcode" @click="submitPasscode">{{
                $t('passcode-button-label')
              }}</b-button>
            </b-field>
            <p v-if="passcodeFailed" class="is-size-6" style="text-align: center; color: red">
              {{ $t('passcode-incorrect-message') }}
            </p>
          </div>
        </div>
      </form>
    </div>

    <p
      v-else
      class="has-text-weight-semibold is-size-4 has-text-grey has-text-centered is-italic"
      style="padding-top: 5em"
    >
      {{ $t('app-finding-appointment-text') }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      passcode: sessionStorage.getItem('passcode'),
      passcodeFailed: false,
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'token']),
    passcodeEnabled() {
      return this.$omwConfig?.omwServer?.passcodeEnabled;
    },
  },
  async created() {
    this.setToken(this.$route.query.token);
    this.setLoading(false);
    if (!this.passcode) sessionStorage.setItem('previouslyLoggedIn', 'false');
    if (!this.passcodeEnabled || this.previouslyLoggedIn()) {
      // If passcode not required or we've already successfully entered with one, then load home page immediately,
      // else this will be called when passcode is submitted
      this.loadHomePage();
    }
  },
  methods: {
    ...mapActions(['setOmwData', 'setLoading', 'setToken', 'setContactCentre', 'setClickOrigin', 'setError']),

    async submitPasscode() {
      sessionStorage.setItem('passcode', this.passcode);
      this.loadHomePage();
    },

    async loadHomePage() {
      try {
        this.setLoading(true);
        await this.setOmwData(this.passcodeEnabled ? this.passcode : undefined);
        sessionStorage.setItem('previouslyLoggedIn', 'true');
        sessionStorage.setItem('alreadyLoaded', 'true');
        this.alreadyLoaded = true;
        await this.routeToHomePage();
      } catch (err) {
        sessionStorage.setItem('previouslyLoggedIn', 'false');
        if (this.passcodeEnabled && err.response.status === 401) {
          // If passcode required and we get a 401 stay on this page and flag the error
          this.passcodeFailed = true;
        } else {
          this.setError(this.$t('error-appointment-not-viewable'));
        }
      } finally {
        this.setLoading(false);
      }
    },

    async routeToHomePage() {
      if (this.$route.query.marketingPreviewUrl) {
        // If this being called as result of a preview from the portal, propagate the preview url
        query.marketingPreviewUrl = this.$route.query.marketingPreviewUrl;
        query.clickUrl = this.$route.query.clickUrl;
      }
      const contactCentre = this.convertTrueFalseStringToBoolean(this.$route.query.contactCentre);

      this.setContactCentre(contactCentre);
      this.$router
        .push({
          name: 'Home',
          query: {
            token: this.token,
          },
        })
        .catch((_err) => {
          /* noop */
        });
    },

    previouslyLoggedIn() {
      return sessionStorage.getItem('previouslyLoggedIn') === 'true';
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-background {
  background-color: $header-background;
}
</style>
