<template>
  <div :style="remainingTimeStyle">{{ $t('rebook-expiry-label') }} {{ minutes }}:{{ seconds }}</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'AppointmentRemainingTime',
  props: {
    timeoutLength: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      timeRemaining: 0,
      retrievalInterval: undefined,
      duration: this.timeoutLength * 60,
    };
  },
  computed: {
    minutes() {
      return Math.floor(this.timeRemaining / 60);
    },
    seconds() {
      const remainder = this.timeRemaining % 60;
      if (remainder < 10) return `0${remainder}`;
      return remainder;
    },
    remainingTimeStyle() {
      if (this.timeRemaining <= 30) {
        return {
          color: 'red',
        };
      }
      return '';
    },
  },
  watch: {
    timeRemaining: {
      handler(newVal) {
        if (newVal <= 0) {
          this.$emit('slots-expired');
          this.clearTimers();
        }
      },
    },
  },
  created() {
    this.timeRemaining = this.duration;
    this.retrievalInterval = setInterval(() => {
      this.timeRemaining = this.timeRemaining - 1;
    }, 1000);
  },
  beforeDestroy() {
    this.clearTimers();
  },
  methods: {
    clearTimers() {
      clearInterval(this.retrievalInterval);
    },
  },
});
</script>
